* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@font-face {
  font-family: Inter;
  src: url("Inter.f41233f0.woff2");
  font-weight: 100 900;
  font-display: fallback;
}

:root {
  --background: #0d0e14;
  --tileBackground: #141621;
  --textBase: 187 205 242;
  --titleColor: rgb(var(--textBase));
  --textColor: rgb(var(--textBase) / .85);
  --accentBase: 195 95 95;
  --focusColor: rgb(var(--accentBase) / .5);
  --linkColor: rgb(var(--accentBase));
  --linkUnderline: rgb(var(--accentBase) / .2);
  --hoveredLinkUnderline: rgb(var(--accentBase) / .75);
  --borderSize: 1px;
}

@media (resolution >= 1.1x) {
  :root {
    --borderSize: calc(1px / 1.1);
  }
}

@media (resolution >= 1.25x) {
  :root {
    --borderSize: calc(1px / 1.25);
  }
}

@media (resolution >= 1.5x) {
  :root {
    --borderSize: calc(1px / 1.5);
  }
}

@media (resolution >= 1.65x) {
  :root {
    --borderSize: calc(1px / 1.75);
  }
}

@media (resolution >= 2x) {
  :root {
    --borderSize: calc(1px / 2);
  }
}

@media (resolution >= 2.5x) {
  :root {
    --borderSize: calc(1px / 2.5);
  }
}

@media (resolution >= 3x) {
  :root {
    --borderSize: calc(1px / 3);
  }
}

@media (resolution >= 4x) {
  :root {
    --borderSize: calc(1px / 4);
  }
}

@media (resolution >= 5x) {
  :root {
    --borderSize: calc(1px / 5);
  }
}

:root {
  --tileBorderRadius: 8px;
  --tileCentricBorderRadius: 8px;
}

@media (width <= 1024px) {
  :root {
    --tileBorderRadius: 0;
  }
}

@media (width <= 768px) {
  :root {
    --tileCentricBorderRadius: 0;
  }
}

:root {
  --tilePadding: 40px;
}

@media (width <= 640px) {
  :root {
    --tilePadding: 24px;
  }
}

:root {
  --transitionDuration: .2s;
  --transitionProperties: box-shadow, border-block-end-color;
  scroll-behavior: smooth;
}

::selection {
  color: inherit;
  background-color: rgb(var(--textBase) / .15);
}

:focus-visible {
  box-shadow: 0 0 0 2px rgb(var(--accentBase) / .5);
}

body {
  background-color: var(--background);
  padding: var(--tilePadding);
  color: var(--textColor);
  transition-duration: var(--transitionDuration);
  cursor: default;
  max-inline-size: 1024px;
  margin: auto;
  font-family: Inter, Ubuntu, San Francisco, Cantarell, Carlito, Segoe UI, Roboto, sans-serif;
  font-size: 18px;
  font-weight: 350;
  line-height: 32px;
  transition-property: padding;
}

@media (width <= 1024px) {
  body {
    padding: 0;
  }
}

.link {
  color: var(--linkColor);
  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties), opacity;
  border-radius: 4px;
  text-decoration: none;
}

.link:hover {
  opacity: .666667;
}

.link:active {
  opacity: .333333;
}

.header {
  transition-duration: var(--transitionDuration);
  grid-template-columns: auto 1fr;
  grid-template-areas: "me name"
                       "me about"
                       "me contacts";
  justify-content: start;
  gap: 24px 56px;
  margin-block-start: var(--tilePadding);
  margin-block-end: calc(var(--tilePadding) * 2);
  transition-property: margin, padding;
  display: grid;
}

@media (width <= 1024px) {
  .header {
    padding-inline: var(--tilePadding);
    margin-block-end: var(--tilePadding);
  }
}

@media (width <= 640px) {
  .header {
    justify-items: center;
    row-gap: calc(var(--tilePadding) * 2);
    padding-inline: var(--tilePadding);
    grid-template-columns: 1fr;
    grid-template-areas: "me"
                         "name"
                         "about"
                         "contacts";
    margin-block-start: calc(var(--tilePadding) * 2);
    margin-block-end: calc(var(--tilePadding) * 3);
  }
}

.header_me {
  grid-area: me;
  block-size: 192px;
  inline-size: 192px;
  position: relative;
}

.header_me img {
  background-color: rgb(var(--accentBase));
  border-radius: 50%;
}

.header_me:before, .header_me:after {
  --color: var(--textBase);
  content: "";
  z-index: -1;
  inset: calc(-1 * var(--padding));
  block-size: calc(100% + var(--padding) * 2);
  inline-size: calc(100% + var(--padding) * 2);
  border-radius: 50%;
  animation-name: rotate, flick;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal, alternate;
  display: block;
  position: absolute;
}

.header_me:before {
  --padding: 20px;
  --shift: 4px;
  background-color: rgb(var(--color) / .1);
  animation-duration: 11s, 13s;
  animation-delay: -3s;
}

.header_me:after {
  --padding: 8px;
  --shift: -2px;
  background-color: rgb(var(--color) / .3);
  animation-duration: 5s, 7s;
}

.header_name {
  color: var(--titleColor);
  grid-area: name;
  align-self: start;
  margin: 0;
  font-size: 56px;
  font-weight: 800;
  line-height: 1;
}

.header_about {
  text-wrap: balance;
  grid-area: about;
  align-self: center;
  margin: 0;
  font-weight: 450;
}

@media (width <= 640px) {
  .header_about {
    text-align: center;
  }
}

.contacts {
  transition-duration: var(--transitionDuration);
  flex-wrap: wrap;
  grid-area: contacts;
  justify-content: center;
  place-self: end start;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition-property: margin-inline-start, margin-inline-end;
  display: flex;
}

@media (width <= 640px) {
  .contacts {
    justify-self: center;
  }
}

.contacts:is(:hover, :active) :where(.contact_link) {
  opacity: .75;
}

.contact_icon {
  fill: var(--linkColor);
  transition-duration: var(--transitionDuration);
  transition-property: transform;
  display: block;
}

.contact_link {
  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties), opacity;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
}

.contact_link:hover:not(:active) {
  opacity: 1;
}

.contact_link:hover:not(:active) .contact_icon {
  transform: translateY(-4px);
}

.contact_link:active {
  opacity: .25;
}

.section {
  margin-block-end: var(--tilePadding);
}

.section:target .section_title:after {
  opacity: 1;
  color: var(--linkColor);
}

.section_title {
  color: var(--titleColor);
  transition-duration: var(--transitionDuration);
  border-radius: 4px;
  justify-content: space-between;
  margin: 0;
  margin-block-end: 40px;
  font-size: 40px;
  line-height: 1;
  text-decoration: none;
  transition-property: margin-inline-start, margin-inline-end;
  display: flex;
}

@media (width <= 1024px) {
  .section_title {
    margin-inline: var(--tilePadding);
  }
}

.section_title:after {
  content: "#";
  opacity: 0;
  transition-duration: var(--transitionDuration);
  transition-properties: color, opacity;
  margin-inline-start: 24px;
  font-weight: 900;
}

.section_title:hover:after, .section_title:focus-visible:after {
  opacity: .5;
}

.section_title:active:after {
  opacity: 1;
}

.section_title h2 {
  font-size: inherit;
  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties);
  flex-grow: 1;
  margin: 0;
  font-weight: 600;
}

.section_list {
  gap: var(--tilePadding);
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (width <= 768px) {
  .section_list {
    grid-template-columns: 1fr;
  }
}

.item {
  column-gap: var(--tilePadding);
  padding: var(--tilePadding);
  border-radius: var(--tileBorderRadius);
  background-color: var(--tileBackground);
  transition-duration: var(--transitionDuration);
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "title"
                       "about"
                       "links";
  row-gap: 24px;
  transition-property: background-color, box-shadow;
  display: grid;
}

.item.-large {
  grid-column: 1 / -1;
  grid-template-areas: "icon title"
                       "icon about"
                       "icon links";
}

@media (width <= 640px) {
  .item.-large {
    grid-template-columns: auto 1fr;
    grid-template-areas: "icon title"
                         "about about"
                         "links links";
  }
}

@media (width <= 1024px) {
  .item:not(.-large):nth-of-type(2n) {
    border-start-start-radius: var(--tileCentricBorderRadius);
    border-end-start-radius: var(--tileCentricBorderRadius);
  }

  .item:not(.-large):nth-of-type(odd) {
    border-start-end-radius: var(--tileCentricBorderRadius);
    border-end-end-radius: var(--tileCentricBorderRadius);
  }
}

.item:target {
  background-color: rgb(var(--accentBase) / .2);
}

.item:target .item_title:after {
  opacity: 1;
  color: var(--linkColor);
}

.item_icon {
  grid-area: icon;
  width: 128px;
  height: auto;
}

@media (width <= 640px) {
  .item_icon {
    width: auto;
    height: 32px;
  }
}

.item_title {
  color: var(--titleColor);
  border-radius: 4px;
  grid-area: title;
  justify-content: space-between;
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  text-decoration: none;
  display: flex;
}

.item_title:after {
  content: "#";
  opacity: 0;
  transition-duration: var(--transitionDuration);
  transition-properties: opacity;
  margin-inline-start: 24px;
  font-weight: 900;
}

.item_title:hover:after, .item_title:focus-visible:after {
  opacity: .5;
}

.item_title:active:after {
  opacity: 1;
}

.item_title h3 {
  font-size: inherit;
  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties);
  flex-grow: 1;
  margin: 0;
  font-weight: 600;
}

.item_about {
  grid-area: about;
  margin: 0;
}

.item_links {
  flex-wrap: wrap;
  grid-area: links;
  justify-self: start;
  gap: 8px 24px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.item_links:is(:hover, :active) :where(.item_link) {
  opacity: .75;
}

.item_link {
  color: var(--linkColor);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties), padding, margin, border-color, opacity;
  border-block-end: var(--borderSize) solid var(--linkUnderline);
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.item_link:hover:not(:active) {
  border-block-end-color: var(--hoveredLinkUnderline);
  opacity: 1;
  margin-block-start: -4px;
  padding-block-end: 4px;
}

.item_link:focus-visible {
  border-block-end-color: #0000;
  border-radius: 4px;
}

.item_link:active {
  opacity: .25;
}

.footer {
  text-wrap: balance;
  color: rgb(var(--textBase) / .5);
  transition-duration: var(--transitionDuration);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  transition-property: padding;
}

@media (width <= 1024px) {
  .footer {
    padding: var(--tilePadding);
    padding-block-start: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0) translateY(var(--shift));
  }

  to {
    transform: rotate(1turn) translateY(var(--shift));
  }
}

@keyframes flick {
  from {
    opacity: 1;
  }

  to {
    opacity: .5;
  }
}
/*# sourceMappingURL=index.f1168d49.css.map */
