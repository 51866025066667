* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@custom-media --tablet (width <= 1024px);
@custom-media --oneColumn (width <= 768px);
@custom-media --mobile (width <= 640px);

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.woff2");
  font-weight: 100 900;
  font-display: fallback;
}

:root {
  --background: #0D0E14;
  --tileBackground: #141621;

  --textBase: 187 205 242;
  --titleColor: rgb(var(--textBase));
  --textColor: rgb(var(--textBase) / 0.85);

  --accentBase: 195 95 95;
  --focusColor: rgb(var(--accentBase) / 0.5);
  --linkColor: rgb(var(--accentBase));
  --linkUnderline: rgb(var(--accentBase) / 0.2);
  --hoveredLinkUnderline: rgb(var(--accentBase) / 0.75);

  --borderSize: 1px;

  @media (resolution >= 1.1dppx) {
    --borderSize: calc(1px / 1.1);
  }

  @media (resolution >= 1.25dppx) {
    --borderSize: calc(1px / 1.25);
  }

  @media (resolution >= 1.5dppx) {
    --borderSize: calc(1px / 1.5);
  }

  @media (resolution >= 1.65dppx) {
    --borderSize: calc(1px / 1.75);
  }

  @media (resolution >= 2dppx) {
    --borderSize: calc(1px / 2);
  }

  @media (resolution >= 2.5dppx) {
    --borderSize: calc(1px / 2.5);
  }

  @media (resolution >= 3dppx) {
    --borderSize: calc(1px / 3);
  }

  @media (resolution >= 4dppx) {
    --borderSize: calc(1px / 4);
  }

  @media (resolution >= 5dppx) {
    --borderSize: calc(1px / 5);
  }

  --tileBorderRadius: 8px;
  --tileCentricBorderRadius: 8px;

  @media (--tablet) {
    --tileBorderRadius: 0;
  }

  @media (--oneColumn) {
    --tileCentricBorderRadius: 0;
  }

  --tilePadding: 40px;

  @media (--mobile) {
    --tilePadding: 24px;
  }

  --transitionDuration: 200ms;
  --transitionProperties: box-shadow, border-block-end-color;

  scroll-behavior: smooth;
}

::selection {
  color: inherit;
  background-color: rgb(var(--textBase) / 0.15);
}

*:focus-visible {
  box-shadow: 0 0 0 2px rgb(var(--accentBase) / 0.5);
}

body {
  background-color: var(--background);

  max-inline-size: 1024px;
  padding: var(--tilePadding);
  margin: auto;

  color: var(--textColor);
  font-family: "Inter", "Ubuntu", "San Francisco", "Cantarell", "Carlito",
    "Segoe UI", "Roboto", sans-serif;
  font-weight: 350;
  font-size: 18px;
  line-height: 32px;

  transition-duration: var(--transitionDuration);
  transition-property: padding;

  cursor: default;

  @media (--tablet) {
    padding: 0;
  }
}

.link {
  color: var(--linkColor);

  border-radius: 4px;
  text-decoration: none;

  transition-duration: var(--transitionDuration);
  transition-property: var(--transitionProperties), opacity;

  &:hover {
    opacity: calc(2 / 3);
  }

  &:active {
    opacity: calc(1 / 3);
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "me name"
    "me about"
    "me contacts";
  column-gap: 56px;
  row-gap: 24px;
  justify-content: start;

  margin-block-start: var(--tilePadding);
  margin-block-end: calc(var(--tilePadding) * 2);

  transition-duration: var(--transitionDuration);
  transition-property: margin, padding;

  @media (--tablet) {
    margin-block-end: var(--tilePadding);
    padding-inline: var(--tilePadding);
  }

  @media (--mobile) {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "me"
      "name"
      "about"
      "contacts";
    row-gap: calc(var(--tilePadding) * 2);

    margin-block-start: calc(var(--tilePadding) * 2);
    margin-block-end: calc(var(--tilePadding) * 3);
    padding-inline: var(--tilePadding);
  }

  &_me {
    grid-area: me;
    inline-size: 192px;
    block-size: 192px;
    position: relative;

    img {
      border-radius: 50%;
      background-color: rgb(var(--accentBase));
    }

    &::before,
    &::after {
      --color: var(--textBase);

      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      inset: calc(-1 * var(--padding));
      block-size: calc(100% + var(--padding) * 2);
      inline-size: calc(100% + var(--padding) * 2);
      animation-name: rotate, flick;
      animation-iteration-count: infinite;
      animation-direction: normal, alternate;
      animation-timing-function: linear;
    }

    &::before {
      --padding: 20px;
      --shift: 4px;
      background-color: rgb(var(--color) / 0.1);
      animation-duration: 11s, 13s;
      animation-delay: -3s;
    }

    &::after {
      --padding: 8px;
      --shift: -2px;
      background-color: rgb(var(--color) / 0.3);
      animation-duration: 5s, 7s;
    }
  }

  &_name {
    grid-area: name;
    align-self: start;

    color: var(--titleColor);
    font-size: 56px;
    font-weight: 800;
    line-height: 1;

    margin: 0;
  }

  &_about {
    grid-area: about;
    align-self: center;

    font-weight: 450;
    text-wrap: balance;

    margin: 0;

    @media (--mobile) {
      text-align: center;
    }
  }
}

.contacts {
  grid-area: contacts;
  align-self: end;
  justify-self: start;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 0;
  margin: 0;
  gap: 24px;

  list-style: none;

  transition-duration: var(--transitionDuration);
  transition-property: margin-inline-start, margin-inline-end;

  @media (--mobile) {
    justify-self: center;
  }

  &:is(:hover, :active) :where(.contact_link) {
    opacity: 0.75;
  }
}

.contact {
  &_icon {
    display: block;
    fill: var(--linkColor);

    transition-duration: var(--transitionDuration);
    transition-property: transform;
  }

  &_link {
    display: flex;
    text-decoration: none;
    border-radius: 4px;

    transition-duration: var(--transitionDuration);
    transition-property: var(--transitionProperties), opacity;

    &:hover:not(:active) {
      opacity: 1;

      .contact_icon {
        transform: translateY(-4px);
      }
    }

    &:active {
      opacity: 0.25;
    }
  }
}

.section {
  margin-block-end: var(--tilePadding);

  &:target .section_title::after {
    opacity: 1;
    color: var(--linkColor);
  }

  &_title {
    display: flex;
    justify-content: space-between;

    color: var(--titleColor);
    font-size: 40px;
    line-height: 1;
    text-decoration: none;
    border-radius: 4px;

    margin: 0;
    margin-block-end: 40px;

    transition-duration: var(--transitionDuration);
    transition-property: margin-inline-start, margin-inline-end;

    @media (--tablet) {
      margin-inline: var(--tilePadding);
    }

    &::after {
      content: "#";
      font-weight: 900;
      margin-inline-start: 24px;
      opacity: 0;

      transition-duration: var(--transitionDuration);
      transition-properties: color, opacity;
    }

    &:hover::after,
    &:focus-visible::after {
      opacity: 0.5;
    }

    &:active::after {
      opacity: 1;
    }

    h2 {
      flex-grow: 1;
      margin: 0;

      font-size: inherit;
      font-weight: 600;

      transition-duration: var(--transitionDuration);
      transition-property: var(--transitionProperties);
    }
  }

  &_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--tilePadding);

    padding: 0;
    margin: 0;

    list-style: none;

    @media (--oneColumn) {
      grid-template-columns: 1fr;
    }
  }
}

.item {
  display: grid;
  grid-template-areas:
    "title"
    "about"
    "links";
  grid-template-rows: auto 1fr auto;
  column-gap: var(--tilePadding);
  row-gap: 24px;
  padding: var(--tilePadding);

  border-radius: var(--tileBorderRadius);
  background-color: var(--tileBackground);

  transition-duration: var(--transitionDuration);
  transition-property: background-color, box-shadow;

  &.-large {
    grid-template-areas:
      "icon title"
      "icon about"
      "icon links";

    grid-column: 1 / -1;

    @media (--mobile) {
      grid-template-areas:
        "icon  title"
        "about about"
        "links links";
      grid-template-columns: auto 1fr;
    }
  }

  @media (--tablet) {
    &:not(.-large) {
      &:nth-of-type(2n) {
        border-start-start-radius: var(--tileCentricBorderRadius);
        border-end-start-radius: var(--tileCentricBorderRadius);
      }

      &:nth-of-type(2n + 1) {
        border-start-end-radius: var(--tileCentricBorderRadius);
        border-end-end-radius: var(--tileCentricBorderRadius);
      }
    }
  }

  &:target {
    background-color: rgb(var(--accentBase) / 0.2);

    .item_title::after {
      opacity: 1;
      color: var(--linkColor);
    }
  }

  &_icon {
    grid-area: icon;
    width: 128px;
    height: auto;

    @media (--mobile) {
      height: 32px;
      width: auto;
    }
  }

  &_title {
    display: flex;
    justify-content: space-between;

    grid-area: title;

    color: var(--titleColor);
    font-size: 28px;
    line-height: 32px;
    text-decoration: none;
    border-radius: 4px;

    margin: 0;

    &::after {
      content: "#";
      font-weight: 900;
      opacity: 0;

      margin-inline-start: 24px;

      transition-duration: var(--transitionDuration);
      transition-properties: opacity;
    }

    &:hover::after,
    &:focus-visible::after {
      opacity: 0.5;
    }

    &:active::after {
      opacity: 1;
    }

    h3 {
      flex-grow: 1;
      margin: 0;

      font-size: inherit;
      font-weight: 600;

      transition-duration: var(--transitionDuration);
      transition-property: var(--transitionProperties);
    }
  }

  &_about {
    grid-area: about;

    margin: 0;
  }

  &_links {
    display: flex;
    flex-wrap: wrap;

    grid-area: links;
    justify-self: start;

    padding: 0;
    margin: 0;
    column-gap: 24px;
    row-gap: 8px;

    list-style: none;

    &:is(:hover, :active) :where(.item_link) {
      opacity: 0.75;
    }
  }

  &_link {
    color: var(--linkColor);

    display: block;

    font-weight: 500;
    text-decoration: none;
    box-decoration-break: clone;

    transition-duration: var(--transitionDuration);
    transition-property: var(--transitionProperties), padding, margin,
      border-color, opacity;
    border-block-end: var(--borderSize) solid var(--linkUnderline);

    &:hover:not(:active) {
      margin-block-start: -4px;
      padding-block-end: 4px;
      border-block-end-color: var(--hoveredLinkUnderline);
      opacity: 1;
    }

    &:focus-visible {
      border-radius: 4px;
      border-block-end-color: transparent;
    }

    &:active {
      opacity: 0.25;
    }
  }
}

.footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-wrap: balance;
  color: rgb(var(--textBase) / 0.5);

  transition-duration: var(--transitionDuration);
  transition-property: padding;

  @media (--tablet) {
    padding: var(--tilePadding);
    padding-block-start: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0) translateY(var(--shift));
  }

  to {
    transform: rotate(1turn) translateY(var(--shift));
  }
}

@keyframes flick {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
